import { global } from ":global";
import { _Firebase } from "./_model";

export async function onQuestionUpdate(props: _Firebase.OnQuestionUpdateProps) {
  global.fb.rtdb.onValueChanged({
    path: "/open-question/" + String(props.entity.id),
    callback: (snap) => {
      props.onUpdate?.({ ...props.entity, ...snap.val() });
    },
  });
}
